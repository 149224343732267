import React from "react";
import { Navigate } from "react-router-dom";
import {jwtDecode} from "jwt-decode";

const PrivateRoute = ({ children }) => {
  const token = localStorage.getItem("token");

  if (!token) {
    return <Navigate to="/auth" replace />;
  }

  try {
    const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000;

    if (decodedToken.exp < currentTime) {
      localStorage.removeItem("token");
      alert("Session expired. Please log in again.");
      return <Navigate to="/auth" replace />;
    }

    return children;
  } catch (error) {
    localStorage.removeItem("token");
    alert("Invalid session. Please log in again.");
    return <Navigate to="/auth" replace />;
  }
};

export default PrivateRoute;
