import React, { useState, useEffect } from "react";
import CardContent from "@mui/material/CardContent";
import axios from "axios";
import Divider from "@mui/material/Divider";

const MedicinesStats = ({ onChangeType, type }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  async function getData() {
    setLoading(true);
    setError(false);
    try {
      const res = await axios.get(`https://api.medstown.com/pharmacy/medicine/stats`);
      if (res.data?.data) {
        setData(res.data.data);
      } else {
        setError(true);
      }
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getData();
  }, []);

  if (loading) {
    return (
      <div style={styles.loadingContainer}>
        <p style={{ textAlign: "center" }}>Loading... </p>
      </div>
    );
  }

  if (error) {
    return (
      <div style={styles.errorContainer}>
        <p style={{ textAlign: "center", color: "red" }}>
          Failed to load data. Please try again later.
        </p>
        <button
          onClick={() => {
            setError(false);
            getData();
          }}
        >
          Retry
        </button>
      </div>
    );
  }

  if (!data) {
    return null;
  }

  return (
    <div style={styles.divContainer}>
      <div
        style={{
          ...styles.cardWrapper,
          border: type === "totalCount" ? "2px solid #014D4D" : "none",
        }}
        onClick={() => {
          if (type !== "totalCount") {
            onChangeType("totalCount");
          }
        }}
      >
        <CardContent style={styles.cardContent}>
          <p className="text-sm font-medium text-gray-600">Total</p>
          <p className="font-large text-black-600 text-4xl">
            {data?.totalCount}
          </p>
        </CardContent>
      </div>

      <Divider orientation="vertical" />

      <div
        style={{
          ...styles.cardWrapper,
          border: type === "prescriptionCount" ? "2px solid #014D4D" : "none",
        }}
        onClick={() => {
          if (type !== "prescriptionCount") {
            onChangeType("prescriptionCount");
          }
        }}
      >
        <CardContent style={styles.cardContent}>
          <p className="text-sm font-medium text-gray-600">Prescripted</p>
          <p className="font-large text-black-600 text-4xl">
            {data?.prescriptionCount}
          </p>
        </CardContent>
      </div>

      <Divider orientation="vertical" />

      <div
        style={{
          ...styles.cardWrapper,
          border: type === "nonprescriptionCount" ? "2px solid #014D4D" : "none",
        }}
        onClick={() => {
          if (type !== "nonprescriptionCount") {
            onChangeType("nonprescriptionCount");
          }
        }}
      >
        <CardContent style={styles.cardContent}>
          <p className="text-sm font-medium text-gray-600">Non prescripted</p>
          <p className="font-large text-black-600 text-4xl">
            {data?.nonprescriptionCount}
          </p>
        </CardContent>
      </div>

      <Divider orientation="vertical" />

      <div
        style={{
          ...styles.cardWrapper,
          border: type === "nonSellableCount" ? "2px solid #014D4D" : "none",
        }}
        onClick={() => {
          if (type !== "nonSellableCount") {
            onChangeType("nonSellableCount");
          }
        }}
      >
        <CardContent style={styles.cardContent}>
          <p className="text-sm font-medium text-gray-600">Non Sellable</p>
          <p className="font-large text-black-600 text-4xl">
            {data?.nonSellableCount}
          </p>
        </CardContent>
      </div>
    </div>
  );
};

const styles = {
  divContainer: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    padding: "20px",
    backgroundColor: "#f9f9f9",
    borderRadius: "10px",
    gap: 12,
  },
  cardWrapper: {
    flex: 1,
    textAlign: "center",
    cursor: "pointer",
    borderRadius: "10px",
    backgroundColor: "#fff",
  },
  cardContent: {
    padding: "20px",
    textAlign: "center",
    borderRadius: "10px",
    backgroundColor: "#fff",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  },
  loadingContainer: {
    textAlign: "center",
    padding: "20px",
  },
  errorContainer: {
    textAlign: "center",
    padding: "20px",
    color: "red",
  },
};

export default MedicinesStats;
